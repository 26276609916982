import "../styles/loader.css"

function Loader() {
  return (
    <div className="clloader">
      <div className="clspinner"></div>
    </div>
  );
}

export default Loader;
